<template>
  <div  class="main-container">  
    <div class="bg"></div>
     <PageLoader v-if="showLoading"/>


    <div class="header"> 
      <div class="d-flex" style="width:50%; cursor: pointer;" @click="goToDefault"> 
        <div class="mr-3"><v-icon name="chevron-left" class="chevron-left" /></div>
        <h5>BACK</h5>
      </div>
      <div>
        <a href="#" class="logout" v-on:click="openLogout">LOGOUT</a>
      </div>
    </div>

    <div class="main-body">
                    <div class="text-center text-white"><h1>PENDING ORDERS</h1></div>
        <div class=" justify-content-between d-flex flex-wrap">
            <div style="width:45%;border:2px white solid;border-radius:10px;padding:20px;" class="mt-4">
            <div class="text-center text-white" style="font-weight:bold;">BUY</div>
       <div id="main-buy">
           
             <table>
                  <thead>
                    <tr>
                       <th class="left-col">Price ({{ init.currency.fromCurrency }})  </th>
                      <th class="right-col">Quantity ({{ init.currency.toCurrency }})</th> 
                      <th class="right-col">Timestamp</th> 
                      <th class="right-col">Type</th> 
                    </tr>
                  </thead>

                  <tbody> 
                    <tr v-for="data in pendingBuyOrders">
                      <td class="left-col">{{ parseFloat(data.xchgRate).toFixed(4) }}</td>
                      <td class="right-col">{{ parseFloat(data.remainingOrder).toFixed(8) }}</td> 
                      <td class="right-col">{{ data.txnDate }}</td> 
                      <td class="right-col">{{ data.txnType }}</td> 
                    </tr>

                    <tr v-if="pendingBuyOrders.length == 0">
                      <td colspan="3">No Data found</td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>

            <div style="width:45%;border:2px white solid;border-radius:10px;padding:20px;" class="mt-4">
                <div class="text-center text-white" style="font-weight:bold;background-color:#011434;">SELL</div>
          <div id="main-sell">
            <!-- <div id="sell-price-column" class="text-left">
              <h6 class="sell-price-header">Price</h6>
              <div v-for="h in init.history" :key="h.hash">
                <h6 class="buy-price-text" v-if="h.side == 'S'">{{ h.xchgRate.toFixed(8) }}</h6>
              </div>
            </div>
            <div id="sell-quantity-column">
              <h6 class="sell-quantity-header">Quantity</h6>
              <div v-for="h in init.history" :key="h.hash">
                <h6 class="buy-price-text" v-if="h.side == 'S'">{{ init.currency.fromCurrency }} {{ h.sourceAmount }}</h6>
              </div>
            </div> -->
            <table>
                  <thead >
                    <tr>
                      <th class="left-col">Price ({{ init.currency.fromCurrency }})  </th>
                      <th class="right-col">Quantity ({{ init.currency.toCurrency }})</th> 
                      <th class="right-col">Timestamp</th> 
                      <th class="right-col">Type</th> 
                    </tr>
                  </thead>

                  <tbody> 
                    <tr v-for="data in pendingSellOrders">
                      <td class="left-col">{{ parseFloat(data.xchgRate).toFixed(4) }}</td>
                      <td class="right-col">{{ parseFloat(data.remainingOrder).toFixed(8) }}</td> 
                      <td class="right-col">{{ data.txnDate }}</td> 
                      <td class="right-col">{{ data.txnType }}</td> 
                    </tr>

                    <tr v-if="pendingSellOrders.length == 0">
                      <td colspan="3">No Data found</td>
                    </tr>
                  </tbody>
                </table>


          </div>
            </div>
        </div>
        
            
    </div>

    <ConfirmCancel @close="cancelOrder" :txn-id="selectedTxnId" v-if="showConfirmCancelModal"/>
    <ConfirmLogout @close="openLogout" v-if="showLogoutModal"/>
    <Login  @close="openLogin" @openSignUp="openSignup" v-if="showLoginModal"/>
    <Signup  @close="openSignup" @openLogin="openLogin" v-if="showSignupModal"/>
    </div>

</template>

<script>

  import qs from 'querystring'
  import numeral from 'numeral'  
  import ConfirmLogout from '@/components/modals/LogoutModal.vue'
  import ConfirmCancel from '@/components/modals/ConfirmCancel.vue'
  import Login from '../components/modals/Login.vue'
  import Signup from '../components/modals/Signup.vue'
  import PageLoader from '../components/PageLoader.vue'



  export default {
    name: 'history',
    components: {
      ConfirmLogout,
      PageLoader,
      Login,
      Signup,        
      ConfirmCancel
    },
    data: function() {
      return  {
        showLoading: true, 
        showLogoutModal: false,
        showConfirmCancelModal: false,
        showLoginModal: false,
        showSignupModal: false,
        state: '11',
        selectedTxnId: "",
        pendingBuyOrders: [],
        pendingSellOrders: []
      }
    },
    computed: {
      init() {
          return this.$store.state.init;
      },

      chooseAsset() {
        this.selectedSubWallet = this.$t('blxDashboard.chooseAsset')
        return this.selectedSubWallet
      }
    },
    methods: {
       getFunds: function() {

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          url: '/cx/funds',
        };

        this.axios(options)
           .then((response) => { 

            this.init.balance = response.data;
            console.log(this.init.balance)

            }).catch((err) => {
              
            })
      },
        changeState(state) {
            this.state = state;

            this.getHistory(state);
        },
        getHistory: function(status){

          let date = new Date();

        let lastDayOfMonth = new Date(date.getFullYear(), date.getMonth()+1, 0);
 
        const params = {
          status: status,
          fromDate: parseFloat(date.getMonth() + 1) + '/01/' + date.getFullYear(),
          toDate: parseFloat(date.getMonth() + 1) + '/'+ lastDayOfMonth.getDate() +'/' + date.getFullYear()
        }

        const data = Object.keys(params)
          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
          .join('&');



        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          data,
          url: '/cx/trade/history',
        };

        this.init.history = [];

        this.axios(options)
          .then((response) => {
              this.init.history = response.data.rowsList
          })
      },
      
      goToDefault() {
        this.$router.push({name: 'MarketDashboard', params: {pair: 'BLXUSDT'}})
      },

    openLogout(){
        this.showLogoutModal = !this.showLogoutModal;
      },
      openLogin(){
        this.showLoginModal = !this.showLoginModal;
        this.showSignupModal = false;
      },
      openSignup(){
        this.showSignupModal = !this.showSignupModal;
        this.showLoginModal = false;
      },

      formatDate: function(time){
        return new Date(time).toLocaleString();
      },
      formatAmount:  function (num) {

        if(isNaN(num)) {
            num = 0;
        }
        return parseFloat(num).toFixed(8)
      },

      cancelOrder: function(txnId){ 
        this.selectedTxnId = txnId;
        this.showConfirmCancelModal = !this.showConfirmCancelModal;
        
        if(!this.showConfirmCancelModal) {
          this.getHistory('00');
        }

      },

      getPairs: function(){

        const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          url: '/pub/market/pairs',
        };

        this.axios(options)
          .then((response) => {

              response.data.forEach((value) => {

                if(value.value == this.$route.params.pair) {
                  this.init.currency.fromCurrency = value.label.substring(value.label.indexOf('/') + 1)
                  this.init.currency.toCurrency = value.label.substring(0, value.label.indexOf('/'))
                }
              })
            })
      },

 
      checkSession: function() {

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          url: '/cx/sessioncheck',
        };

        this.axios(options)
           .then((response) => { 
          
              setTimeout( () => {
                this.showLoading = false;
              },1000)

            }).catch((err) => {
                
              if(err.response.data.msgCode == '00001') {
                this.showLoading = false;
                this.openLogin();
              }  

              if(err.response.data.msgCode == '00020') {

                setTimeout( () => {
                window.location.href = "/#/profile/setup";
               },1000)
                
              }  

            })
            
      },

      getBuyOrders: function(status){
         const params = {
            status: '00',
            market: this.$route.params.pair,
            itemPerPage: '1000'
          }

          const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');

          const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            data,
            url: '/pub/trade/buy/orders',
          };

          this.axios(options)
            .then((response) => {
 

                this.pendingBuyOrders = response.data;
 
            })
     },
     getSellOrders: function(status){
         const params = {
            status: '00',
            market: this.$route.params.pair,
            itemPerPage: '1000'
          }

          const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');

          const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            data,
            url: '/pub/trade/sell/orders',
          };

          this.axios(options)
            .then((response) => {

                this.pendingSellOrders = response.data;

            })
     },


      logout: function(){
            this.$bvModal.show('logout-modal'); 
            console.log('.')
      },
    },

    beforeMount(){

    },

    mounted() {
        this.checkSession();
        this.getBuyOrders();
        this.getSellOrders();
    },

    watch: {

    }
  }
</script>

<style scoped>
  #loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000; 
  color: #FFF;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
      background: #141414;
}
#loading .content {
  display: inline-block;
  text-align: center;
}
#loading .content img {
  display: block;
  margin-bottom: 0.5em;
}
#loading .content .icon {
  font-size: 3em;
}

.fa-icon { 
    color: #FFF;
    fill: #fff;
}
  .z-index-2 {
    z-index: 2;
  }

  .main-container {
    font-family:MSR-Reg;
    height:100%;
  }
  .main-body {
    background-color:#011434 !important;
    padding:30px;
  }
  .bg {
    background-color:#011434 !important;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
    pointer-events: none;
    object-fit: cover;
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    color: #333;
  }
   .hash {
    white-space: nowrap !important;
    width: 20em;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  td, th {
   
    text-align: left;
    padding: 1em;
    font-size: 0.75em;
  }

  td {  
    color: white;
    border-bottom: 1px solid #FFF;
    padding: 1.5em;
  
  }

  th {
    color: #FFF;
  }

  th:first-child { 
    border-top-left-radius: 6px;
  }
  th:last-child { 
    border-top-right-radius: 6px;
  }

  .history-content {
    background-color: #011434 !important;
      border-radius: 6px;
        overflow-x:auto;
  }
    


  .history-container {  
      padding: 1.5em;
      border-radius: 6px;
      flex-direction: column;
      position: relative;
      background-color:#011434 !important;
      padding-top: 5em;
      color: #000;
      background-color: #de8413;
      background-image: linear-gradient(#de8413, #d8962c);
      width: 100%;
      margin: 0 2em;
  }
  
  .history-header {  
    color: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: center;;
  }


  .option-box {

    background: #141414;
    border: 1px solid #8c5b13;
    color: #FFF;
    border-radius: 6px; 
    min-height: 15em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 3em;
    cursor: pointer;
  }
 .content-icon {
      width: 75px;
    }

  .header { 
    
    background: #141414;
    padding: 2em;
    display: flex;
    align-items: end;
    justify-content: space-between;
    color: #FFF;
  }
  .logout {
    color: #FFF;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 1em;
    padding: 0 15em;
  }

  .btn-secondary {
      background:none !important;
      border-color:orange !important;
      color:white !important;
      border-width:2px !important;
      margin-left:5px !important;
    }

    .btn-secondary {
      background:none !important;
      border-color:orange !important;
      color:white !important;
      border-width:2px !important;
      margin-left:5px !important;
    }

    .cancel {
	  color:#8585e5;
	  background:none;
	  border:none;
	  font-family:'PoppinsMedium', sans-serif;
	  width:15%;
    outline:none;
  }

  .cancel:hover {
    color:#8585e5;
  }

  .cancel:focus {
    box-shadow:1px 1px #8585e5;
  }

  .select {
	  color:#8585e5;
	  background:none;
	  border-color:#8585e5;
	  border-width:1px;
	  font-family:'PoppinsMedium', sans-serif;
  }

  .active {
    border:none;
	  border-bottom:1px #8585e5 solid;
	  font-family:'PoppinsMedium', sans-serif;
	  width:15%;
  }

  .column {
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: row ;
  justify-content: space-evenly;
   border: 1px solid black;
}
.row {
    margin: 0 -5px;
    display: flex !important;
    color: white !important;
}
.row:after {
  content: "";
  display: table;
  clear: both;
  
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 
  padding: 16px;
  text-align: center;
  background: transparent;
    backdrop-filter:blur(20px);
	box-shadow:0 0 30px rgba(0,0,0,0.3);
	/* border: 2px solid #8585e5; */
  display: flex;
  color: white !important;
  width: auto;
  margin: 10px;
  
}

#main-buy {
      width: 100%;
      border-radius:10px;
      padding:30px;
      color:white;
      font-family:MSR-Reg;
      font-size: 80%;
    }

    #main-sell {
      width: 100%;
      border-radius:10px;
      padding:30px;
      color:white;
      font-family:MSR-Reg;
      font-size: 80%;
    }

  /*med*/
  @media(min-width: 40em) {
    
  }
  /*large*/
  @media(min-width: 64em) {

   
  }
  /*xl*/
  @media(min-width: 87.5em) {
    
  }


  /*sm*/
  @media(max-width: 39.9375em) {
    .content {
      padding: 0 3em;
    }

    .transaction-content {
      padding: 3em;
    }
    .column {
      flex-direction: column;
    }
  }
  /*med*/
  @media(max-width: 63.9375em) {

    
    
  }

  /*large*/
  @media(max-width: 87.4375em) {
    
  }


</style>